import "../scss/main.scss";



// Detect how far are we from the top of the page


// $(document).ready(function () {
//     console.log("test")
//     $(window).scroll(function (e) {
//         let windowTop = $(this).scrollTop();
//         // Loop through every navigation menu item
//         $('.header__nav-list a').each(function (event) {

//             // Check for the current navigation item associated section
//             // Check how far the associated section is from the top
//             // If the associated section is as far as the user have scrolled, add 'active' class to the item.
//             if (windowTop >= $($(this).attr('href')).offset().top - 100) {
//                 // Remove 'active' from previously highlighted menu items
//                 $('.header__nav-list .active').removeClass('active');

//                 // Highlight the current menu item by adding 'active' class
//                 $(this).addClass('active');
//             }
//         });
//       });
// });

document.addEventListener("DOMContentLoaded", () => {
    console.log("test")
    var minheigt = 100000;
    document.addEventListener("scroll", (e) => {
        var windowTop = window.pageYOffset || document.documentElement.scrollTop;

        document.querySelectorAll('.header__nav-list a').forEach(function (elem) {
            var anchor = document.querySelector(elem.getAttribute("href"));
            if(anchor!= null){
               
                var anchorOffset = window.pageYOffset + anchor.getBoundingClientRect().top;
                minheigt = Math.min(anchorOffset,minheigt);
                // console.log("scroll",windowTop, anchorOffset - 100)
                if (windowTop >= anchorOffset - 100) {
                    document.querySelectorAll('.header__nav-list .active').forEach(function (elem) {
                        elem.classList.remove('active');
                    });
                    elem.classList.add('active');
                }
                if(windowTop < minheigt - 100){
                    document.querySelectorAll('.header__nav-list .active').forEach(function (elem) {
                        elem.classList.remove('active');
                    });
                }
            }
        });
    });
});
